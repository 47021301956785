export enum SubstitutionRequestStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export function getSubstitutionRequestStatusLabel(
  status?: SubstitutionRequestStatus
): string {
  if (!status) return '';
  switch (status) {
    case SubstitutionRequestStatus.PENDING:
      return 'Pending';
    case SubstitutionRequestStatus.APPROVED:
      return 'Approved';
    case SubstitutionRequestStatus.REJECTED:
      return 'Rejected';
    default:
      return '';
  }
}
