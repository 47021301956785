import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import AuthGuard from '~modules/auth/auth-guard';
import { UserRole } from '~modules/users/types/user-role-enum';

const Campaigns = lazy(() => import('./pages/Campaigns'));
const AddCampaign = lazy(() => import('./pages/AddCampaign'));
const EditCampaign = lazy(() => import('./pages/EditCampaign'));

export const campaignsRoutes = {
  home: {
    title: () => 'Campaigns',
    path: () => '/campaigns',
    roles: [UserRole.ADMIN],
  },
  add: {
    title: () => 'Add New Campaign',
    path: () => '/campaigns/add',
    roles: [UserRole.ADMIN],
  },
  edit: {
    title: () => 'Edit Campaign',
    path: (id?: string) => `/campaigns/${id ? id : ':id'}`,
    roles: [UserRole.ADMIN],
  },
};

export function CampaignsRoutes(): RouteObject[] {
  return [
    {
      path: campaignsRoutes.home.path(),
      element: (
        <AuthGuard roles={campaignsRoutes.home.roles}>
          <Campaigns title={campaignsRoutes.home.title()} />
        </AuthGuard>
      ),
    },
    {
      path: campaignsRoutes.add.path(),
      element: (
        <AuthGuard roles={campaignsRoutes.add.roles}>
          <AddCampaign title={campaignsRoutes.add.title()} />
        </AuthGuard>
      ),
    },
    {
      path: campaignsRoutes.edit.path(),
      element: (
        <AuthGuard roles={campaignsRoutes.edit.roles}>
          <EditCampaign title={campaignsRoutes.edit.title()} />
        </AuthGuard>
      ),
    },
  ];
}
