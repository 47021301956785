import { mdiLoading } from '@mdi/js';
import Icon from '@mdi/react';

export interface SpinnerLoaderProps extends React.PropsWithChildren {
  className?: string;
  size?: number;
}

export const SpinnerLoader: React.FC<SpinnerLoaderProps> = ({
  className,
  size,
}) => (
  <div className={className}>
    <Icon
      path={mdiLoading}
      size={size || 1}
      className={'animate-spin rounded-full ring-2 ring-current ring-inset'}
    />
  </div>
);
