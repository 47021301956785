import React from 'react';
import logoUrl from '~assets/images/ns-logo.png';

export default function SplashScreen() {
  return (
    <>
      <div className={'h-screen flex flex-col justify-center'}>
        <div className="flex flex-row justify-center">
          <img src={logoUrl} alt={'Logo'} />
        </div>
      </div>
    </>
  );
}
