import { gql } from '@apollo/client';

export default gql`
  query GetPlayerByIds($ids: [uuid!]) {
    players(where: { id: { _in: $ids } }) {
      id
      rating
      cricket_rating
      ohone_rating
      notes {
        id
        created_at
        text
        player_id
      }
      user_id
      user {
        id
        first_name
        last_name
        display_name
        photo_url
        phone
        email
        address
        city
        state
        zip
        role
        status
      }
    }
  }
`;
