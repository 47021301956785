import { gql } from '@apollo/client';

export default gql`
  query GetPlayersByUserId($user_id: uuid!) {
    players(where: { user: { id: { _eq: $user_id } } }) {
      id
      rating
      notes {
        id
        created_at
        text
        player_id
      }
      user_id
      user {
        id
        first_name
        last_name
        display_name
        photo_url
        phone
        email
        address
        city
        state
        zip
        role
        status
      }
    }
  }
`;
