import { gql } from '@apollo/client';

export default gql`
  query GetMatchById($id: uuid!) {
    matches_by_pk(id: $id) {
      id
      updated_at
      code
      type
      week
      index
      date
      flight
      substitutions
      substitution_requests {
        id
        player_id
        substitute_player_id
        player {
          id
          user {
            id
            first_name
            last_name
          }
        }
        substitute_player {
          id
          user {
            id
            first_name
            last_name
          }
        }
        status
        created_at
        updated_at
      }
      home_league_team_id
      home_team_public {
        id
        team_id
        team_name
        team_phone
        captain_id
        captain {
          id
          user_public_info {
            id
            first_name
            last_name
          }
        }
        players {
          id
          user_public_info {
            id
            first_name
            last_name
          }
        }
      }
      home_league_team {
        id
        flight
        captain_id
        captain {
          id
          user {
            id
            first_name
            last_name
            phone
          }
        }
        players {
          id
          user {
            id
            first_name
            last_name
          }
        }
        team_id
        team {
          id
          name
        }
        location_id
        location {
          id
          name
        }
        type
      }
      away_league_team_id
      away_team_public {
        id
        team_id
        team_name
        team_phone
        captain_id
        captain {
          id
          user_public_info {
            id
            first_name
            last_name
          }
        }
        players {
          id
          user_public_info {
            id
            first_name
            last_name
          }
        }
      }
      away_league_team {
        id
        flight
        captain_id
        captain {
          id
          user {
            id
            first_name
            last_name
            phone
          }
        }
        players {
          id
          user {
            id
            first_name
            last_name
          }
        }
        team_id
        team {
          id
          name
        }
        location_id
        location {
          id
          name
        }
        type
      }
      location_id
      location {
        id
        name
        description
        operator_id
        operator {
          id
          company
          user {
            id
            first_name
            last_name
          }
          user_public_info {
            id
            first_name
            last_name
          }
        }
        phone
        address
        city
        state
        zip
        status
      }
      division_id
      division {
        id
        name
        flight
        match_week_day
        start_time
        league_id
      }
      games {
        id
        type
        teams {
          id
          players {
            id
            user_public_info {
              id
              first_name
              last_name
            }
            user {
              id
              first_name
              last_name
            }
          }
          score
          game_id
          league_team_id
        }
        attempts {
          event
          type
          turn
          round
          player_id
          score
          game_id
        }
        played_at
        winner_team_id
        match_id
        cabinet_id
        params
        order
      }
    }
  }
`;
