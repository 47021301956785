
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { accountRoutes } from '~modules/account/routes';

const buildLogoutOptions = (returnUrl?: string) => ({
  logoutParams: {
    returnTo: window.location.origin + accountRoutes.login.path(returnUrl),
  }
});

export function useLogout() {
  const { logout } = useAuth0();

  const wrappedLogout = useCallback((returnUrl?: string) => logout(buildLogoutOptions(returnUrl)), [logout]);
  return wrappedLogout;
}
