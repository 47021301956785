import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import AuthGuard from '~modules/auth/auth-guard';
import { UserRole } from '~modules/users/types/user-role-enum';

const TeamsAdmin = lazy(() => import('./pages/TeamsAdmin'));
const EditTeamAdmin = lazy(() => import('./pages/EditTeamAdmin'));
const Teams = lazy(() => import('./pages/Teams'));
const JoinTeam = lazy(() => import('./pages/JoinTeam'));
const TeamDetails = lazy(() => import('./pages/TeamDetails'));
const AddTeam = lazy(() => import('./pages/AddTeam'));
const EditTeam = lazy(() => import('./pages/EditTeam'));

export const teamsRoutes = {
  admin: {
    list: {
      title: () => 'Teams',
      path: () => '/teams-admin',
      roles: [UserRole.ADMIN],
    },
    edit: {
      title: () => 'Edit Team',
      path: (id?: string) => `/teams-admin/${id || ':id'}`,
      roles: [UserRole.ADMIN],
    },
  },
  player: {
    list: {
      title: () => 'Teams',
      path: () => '/teams',
      roles: [UserRole.PLAYER],
    },
    join: {
      title: () => 'Join Team',
      path: (id?: string) => `/teams/join/${id || ':id'}`,
      roles: [UserRole.PLAYER],
    },
    add: {
      title: () => 'Add Team',
      path: () => '/teams/add',
      roles: [UserRole.PLAYER],
    },
    details: {
      title: () => 'Team Details',
      path: (id?: string) => `/teams/${id || ':id'}`,
      roles: [UserRole.PLAYER],
    },
    edit: {
      title: () => 'Edit Team',
      path: (id?: string) => `/teams/${id || ':id'}/edit`,
      roles: [UserRole.PLAYER],
    },
  }
};

export function TeamsRoutes(): RouteObject[] {
  return [
    {
      path: teamsRoutes.admin.list.path(),
      element: (
        <AuthGuard roles={teamsRoutes.admin.list.roles}>
          <TeamsAdmin title={teamsRoutes.admin.list.title()} />
        </AuthGuard>
      ),
    },
    {
      path: teamsRoutes.admin.edit.path(),
      element: (
        <AuthGuard roles={teamsRoutes.admin.edit.roles}>
          <EditTeamAdmin title={teamsRoutes.admin.edit.title()} />
        </AuthGuard>
      ),
    },
    {
      path: teamsRoutes.player.list.path(),
      element: (
        <AuthGuard roles={teamsRoutes.player.list.roles}>
          <Teams title={teamsRoutes.player.list.title()} />
        </AuthGuard>
      ),
    },
    {
      path: teamsRoutes.player.join.path(),
      element: (
        <AuthGuard roles={teamsRoutes.player.join.roles}>
          <JoinTeam />
        </AuthGuard>
      ),
    },
    {
      path: teamsRoutes.player.add.path(),
      element: (
        <AuthGuard roles={teamsRoutes.player.add.roles}>
          <AddTeam title={teamsRoutes.player.add.title()} />
        </AuthGuard>
      ),
    },
    {
      path: teamsRoutes.player.details.path(),
      element: (
        <AuthGuard roles={teamsRoutes.player.details.roles}>
          <TeamDetails />
        </AuthGuard>
      ),
    },
    {
      path: teamsRoutes.player.edit.path(),
      element: (
        <AuthGuard roles={teamsRoutes.player.edit.roles}>
          <EditTeam title={teamsRoutes.player.edit.title()} />
        </AuthGuard>
      ),
    },
  ];
}
