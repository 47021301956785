import { gql } from '@apollo/client';

export default gql`
  mutation InsertMatchMutation($match: matches_insert_input!) {
    insert_matches_one(
      object: $match
      on_conflict: {
        constraint: matches_pkey
        update_columns: [
          week
          date
          flight
          home_league_team_id
          away_league_team_id
          location_id
          division_id
        ]
      }
    ) {
      id
      updated_at
      code
      type
      week
      index
      date
      flight
      home_league_team_id
      home_league_team {
        id
        flight
        captain_id
        captain {
          id
          user {
            id
            first_name
            last_name
          }
        }
        players {
          id
          user {
            id
            first_name
            last_name
          }
        }
        team_id
        team {
          id
          name
        }
        location_id
        location {
          id
          name
        }
      }
      away_league_team_id
      away_league_team {
        id
        flight
        captain_id
        captain {
          id
          user {
            id
            first_name
            last_name
          }
        }
        players {
          id
          user {
            id
            first_name
            last_name
          }
        }
        team_id
        team {
          id
          name
        }
        location_id
        location {
          id
          name
        }
      }
      location_id
      location {
        id
        name
        description
        operator_id
        operator {
          id
          company
          user {
            first_name
            last_name
          }
        }
        phone
        address
        city
        state
        zip
        status
      }
      division_id
      division {
        id
        name
        flight
        match_week_day
        start_time
        league_id
      }
      games {
        id
        type
        teams {
          id
          players {
            id
            user {
              id
              first_name
              last_name
            }
          }
          score
          game_id
          league_team_id
        }
        attempts {
          event
          type
          turn
          player_id
          score
          game_id
        }
        played_at
        winner_team_id
        match_id
        cabinet_id
        params
      }
    }
  }
`;
