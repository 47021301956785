import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import AuthGuard from '~modules/auth/auth-guard';
import {
  SportType,
  getSportShortLabel,
} from '~modules/leagues/types/sport-type-enum';
import { UserRole } from '~modules/users/types/user-role-enum';

const Leagues = lazy(() => import('../leagues/pages/Leagues'));
const PlayerLeagues = lazy(() => import('../leagues/pages/PlayerLeagues'));
const PlayerLeaguesDetails = lazy(() => import('../leagues/pages/PlayerLeaguesDetails'));

const LeagueDetail = lazy(() => import('../leagues/pages/LeagueDetail'));
const EditMatch = lazy(() => import('../schedule/pages/EditMatch'));
const AddLeague = lazy(() => import('../leagues/pages/AddLeague'));
const MatchDetails = lazy(() => import('../leagues/pages/MatchDetails'));

export const dartsRoutes = {
  playerLeagues: {
    title: () => 'Medalist Darts Leagues',
    path: () => '/darts/leagues',
    roles: [UserRole.PLAYER],
  },
  playerLeaguesDetails: {
    title: () => 'League details',
    path: (id?: string) => `/darts/leagues/${id ? id : ':id/*'}`,
    roles: [UserRole.PLAYER],
  },

  leagues: {
    title: () => 'Medalist Darts Leagues',
    path: () => '/darts/admin-leagues',
    roles: [UserRole.ADMIN],
  },

  addLeague: {
    title: () => 'Add New League',
    path: () => '/darts/admin-leagues/add',
    roles: [UserRole.ADMIN],
  },
  league: {
    title: () => 'League details',
    path: (id?: string) => `/darts/admin-leagues/${id ? id : ':id/*'}`,
    roles: [UserRole.ADMIN],
    children: {
      teams: {
        title: () => 'League Teams',
        path: (teamId?: string) => `teams/${teamId ? teamId : ':leagueTeamId'}`,
      },
      divisions: {
        title: () => 'Add Divisions',
        path: () => `divisions`,
      },
      assignTeams: {
        title: () => 'Assign Teams',
        path: () => `assign-teams`,
      },
      reviewSchedule: {
        title: () => 'Review Schedule',
        path: () => `schedule`,
      },
    }
  },
  editMatch: {
    title: () => 'Edit Match',
    path: (leagueId?: string, matchId?: string) =>
      `/darts/admin-leagues/${leagueId ? leagueId : ':leagueId'}/schedule/${matchId ? matchId : ':matchId'
      }/edit`,
    roles: [UserRole.ADMIN],
  },
  matchDetails: {
    title: () => `Match Details`,
    path: (leagueId?: string, matchId?: string) =>
      `/darts/leagues/${leagueId ? leagueId : ':leagueId'}/schedule/${matchId ? matchId : ':matchId'
      }/details`,
    roles: [UserRole.ADMIN, UserRole.PLAYER],
  },
  games: {
    title: () => 'Games',
    path: () => '/darts/games',
    roles: [UserRole.ADMIN],
  },

  leagueSchedule: {
    title: () => `Schedule`,
    path: (leagueId?: string) =>
      `/darts/admin-leagues/${leagueId ? leagueId : ':id'}/schedule`,
    roles: [UserRole.ADMIN],
  },

  // Remove
  leaguePlayers: {
    title: () => `Players`,
    path: (leagueId?: string) => `/darts/admin-leagues/${leagueId ? leagueId : ':id'}/players`,
    roles: [UserRole.ADMIN],
  },
  addLeaguePlayer: {
    title: () => `Add New Player`,
    path: (leagueId?: string) =>
      `/darts/admin-leagues/${leagueId ? leagueId : ':id'}/players/add`,
    roles: [UserRole.ADMIN],
  },
  editLeaguePlayer: {
    title: () => `Edit Player`,
    path: (leagueId?: string, playerId?: string) =>
      `/darts/admin-leagues/${leagueId ? leagueId : ':id'}/players/${playerId ? playerId : ':playerId'
      }`,
    roles: [UserRole.ADMIN],
  },
  leaguePlayerStatistics: {
    title: () => `Player Statistics`,
    path: (leagueId?: string, playerId?: string) =>
      `/darts/admin-leagues/${leagueId ? leagueId : ':id'}/players/${playerId ? playerId : ':playerId'
      }/statistics`,
    roles: [UserRole.ADMIN],
  },
  leagueReports: {
    title: () => `Reports`,
    path: (id?: string) => `/darts/admin-leagues/${id ? id : ':id'}/reports`,
    roles: [UserRole.ADMIN],
  },
  // Remove
};

export function DartsRoutes(): RouteObject[] {
  return [
    {
      path: dartsRoutes.playerLeagues.path(),
      element: (
        <AuthGuard roles={dartsRoutes.playerLeagues.roles}>
          <PlayerLeagues title={dartsRoutes.playerLeagues.title()} />
        </AuthGuard>
      ),
    },
    {
      path: dartsRoutes.playerLeaguesDetails.path(),
      element: (
        <AuthGuard roles={dartsRoutes.playerLeaguesDetails.roles}>
          <PlayerLeaguesDetails title={dartsRoutes.playerLeaguesDetails.title()} />
        </AuthGuard>
      ),
    },
    {
      path: dartsRoutes.leagues.path(),
      element: (
        <AuthGuard roles={dartsRoutes.leagues.roles}>
          <Leagues
            title={dartsRoutes.leagues.title()}
            subMenuTitle={getSportShortLabel(SportType.DART)}
            subMenuItems={[]}
          />
        </AuthGuard>
      ),
    },
    {
      path: dartsRoutes.addLeague.path(),
      element: (
        <AuthGuard roles={dartsRoutes.addLeague.roles}>
          <AddLeague
            title={dartsRoutes.addLeague.title()}
            subMenuTitle={getSportShortLabel(SportType.DART)}
            subMenuItems={[]}
          />
        </AuthGuard>
      ),
    },
    {
      path: dartsRoutes.editMatch.path(),
      element: (
        <AuthGuard roles={dartsRoutes.editMatch.roles}>
          <EditMatch
            title={dartsRoutes.editMatch.title()}
            subMenuTitle={getSportShortLabel(SportType.DART)}
            subMenuItems={[]}
          />
        </AuthGuard>
      ),
    },
    {
      path: dartsRoutes.matchDetails.path(),
      element: (
        <AuthGuard roles={dartsRoutes.matchDetails.roles}>
          <MatchDetails
            title={dartsRoutes.matchDetails.title()}
            subMenuTitle={getSportShortLabel(SportType.DART)}
          />
        </AuthGuard>
      ),
    },
    {
      path: dartsRoutes.league.path(),
      element: (
        <AuthGuard roles={dartsRoutes.league.roles}>
          <LeagueDetail
            subMenuTitle={getSportShortLabel(SportType.DART)}
            subMenuItems={[]}
          />
        </AuthGuard>
      ),
    },
  ];
}
