import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, matchPath, useLocation } from "react-router-dom";
import { accountRoutes } from "~modules/account/routes";
import { GET_USER_BY_ID } from "~modules/users/graphql/queries";
import { User } from '~modules/users/types/User';
import { UserRole } from "~modules/users/types/user-role-enum";
import { getInitialRouteForUser } from "~router/get-initial-route";
import { getUserId, getUserRole } from "./auth-helpers";

export interface AuthGuardProps extends React.PropsWithChildren {
  redirect?: string;
  roles?: UserRole[];
};

const AuthGuard: React.FC<AuthGuardProps> = (
  { redirect, roles = [], children }: AuthGuardProps
) => {
  const { pathname } = useLocation();

  const { isAuthenticated, user, isLoading } = useAuth0();

  const currentUserId = user && getUserId(user);

  const { loading: isLoadingCurrentUser, data: currentUserData } = useQuery(GET_USER_BY_ID, {
    variables: { id: currentUserId },
    skip: !isAuthenticated || !currentUserId
  });

  const currentUser: User = currentUserData?.users_by_pk;

  if (isLoading || isLoadingCurrentUser) {
    return <></>;
  }

  if (!isAuthenticated) {
    const currentPath = window.location.pathname;
    return <Navigate to={redirect || accountRoutes.login.path(currentPath)} />;
  }

  if (!currentUser?.is_valid) {
    const isOnMyAccountsPage = matchPath({ path: accountRoutes.myAccount.path() }, pathname) != null;

    if (!isOnMyAccountsPage) {
      return <Navigate to={accountRoutes.myAccount.path()} />;
    }
  }

  const userRole = user && getUserRole(user);
  const hasPermissions = !roles?.length || (userRole && roles.includes(userRole));
  if (!hasPermissions && isAuthenticated) {
    return <Navigate to={getInitialRouteForUser(user)} />;
  } else if (!hasPermissions && !isAuthenticated) {
    return <Navigate to={accountRoutes.login.path()} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
