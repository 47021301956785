import { createGlobalStyle } from 'styled-components';
import colors from './colors';

const ColorsGlobalStyle = createGlobalStyle`
  :root {
    --color-primary: ${colors.primary};
    --color-on-primary: ${colors['on-primary']};
    --color-on-secondary: ${colors['on-secondary']};
  }
`;

export default ColorsGlobalStyle;
