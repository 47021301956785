import { User } from '@auth0/auth0-react';
import { accountRoutes } from '~modules/account/routes';
import { getUserRole } from '~modules/auth/auth-helpers';
import { dartsRoutes } from '~modules/darts/routes';
import { locationsRoutes } from '~modules/locations/routes';
import { UserRole } from '~modules/users/types/user-role-enum';

export function getInitialRouteForUser(user: User | undefined) {
  if (!user) {
    return accountRoutes.login.path();
  }

  switch (getUserRole(user)) {
    case UserRole.PLAYER:
      return accountRoutes.matchSignIn.path();
    case UserRole.OPERATOR:
      return locationsRoutes.home.path();
    default:
      return dartsRoutes.leagues.path();
  }
}
