import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { useAuth0 } from '@auth0/auth0-react';
import { createClient } from 'graphql-ws';
import { ReactElement, ReactNode, useMemo } from "react";
import { GRAPHQL_API_ENDPOINT } from "~config/global-variables";
import { typePolicies as hardwareTypePolices } from '~modules/hardware/graphql/configs';

function getWebSocketBaseUrl() {
  return GRAPHQL_API_ENDPOINT?.replace('https://', 'wss://')?.replace(
    'http://',
    'ws://'
  );
}

export default function ApolloClientProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const { getAccessTokenSilently } = useAuth0();

  const client = useMemo(() => {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      }

      if (networkError)
        console.log(`[Network error]: ${networkError.toString()}`);
    });

    const authLink = setContext(async (_, { headers }) => {
      const token = await getAccessTokenSilently()

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        }
      };
    });

    const wsLink = new GraphQLWsLink(createClient({
      url: getWebSocketBaseUrl(),
      connectionParams: async () => {
        const token = await getAccessTokenSilently()

        return {
          headers: {
            authorization: token ? `Bearer ${token}` : "",
          },
        }
      },
    }));

    const httpLink = new HttpLink({ uri: GRAPHQL_API_ENDPOINT });

    const batchHttpLink = new BatchHttpLink({
      uri: GRAPHQL_API_ENDPOINT,
      batchMax: 5,
      batchInterval: 10,
    });

    const splitHttpLink = split(
      (op) => {
        const context = op.getContext();
        return !!context.batch;
      },
      batchHttpLink,
      httpLink,
    )

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      authLink.concat(splitHttpLink)
    );

    return new ApolloClient({
      link: errorLink.concat(splitLink),
      cache: new InMemoryCache({
        typePolicies: {
          ...hardwareTypePolices,
        },
      }),
    });
  }, [getAccessTokenSilently])

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
