import { gql } from '@apollo/client';

export default gql`
query GetPlayerPublicView {
  player_public_view(
		order_by: [
			{ first_name: asc },
			{ last_name: asc },
			{ display_id: asc }
		]
	) {
    display_id
		display_name
		first_name
		last_name
		id
		user_id
  }
}`;
