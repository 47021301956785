import { gql } from '@apollo/client';

export default gql`
  mutation UpdateGame($id: uuid!, $payload: games_set_input!) {
    update_games_by_pk(_set: $payload, pk_columns: { id: $id }) {
      id
      type
      played_at
      winner_team_id
      match_id
      cabinet_id
      params
      deleted
    }
  }
`;
