import { ApolloClient } from '@apollo/client';
import { uniqBy } from 'lodash';
import { GET_PLAYERS_BY_IDS } from '~modules/players/graphql/queries';
import { Player } from '~modules/players/types/Player';
import { Match } from '~modules/schedule/types/Match';
import { MatchSubstitution } from '~modules/schedule/types/MatchSubstitution';

function replacePlayerBySubstitutes(
  leagueTeamPlayers: Player[] = [],
  substitutes: Player[] = [],
  substitutions: MatchSubstitution[] = []
): Player[] {
  return leagueTeamPlayers?.map((p) => {
    const substitution = substitutions.find((s) => s.player_id === p.id);
    if (substitution) {
      return substitutes.find((s) => s.id === substitution.substitute_id) || p;
    }
    return p;
  });
}

export async function getMatchPlayers(
  apolloClient: ApolloClient<any>,
  match?: Match
): Promise<Player[]> {
  const substituteIds = match?.substitutions?.map((s) => s.substitute_id) || [];
  let substitutes: Player[] = [];

  if (substituteIds.length > 0) {
    const { data } = await apolloClient.query({
      query: GET_PLAYERS_BY_IDS,
      variables: {
        ids: substituteIds,
      },
    });
    substitutes = data?.players || [];
  }

  const homeTeamPlayers =
    replacePlayerBySubstitutes(
      match?.home_league_team?.players,
      substitutes,
      match?.substitutions
    ) || [];
  const awayTeamPlayers =
    replacePlayerBySubstitutes(
      match?.away_league_team?.players,
      substitutes,
      match?.substitutions
    ) || [];

  return uniqBy([...homeTeamPlayers, ...awayTeamPlayers], 'id');
}
