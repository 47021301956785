import { lazy } from 'react';
import { matchPath, RouteObject } from 'react-router-dom';
import { addQueryParams } from '~helpers/query-string-helper';
import AuthGuard from '~modules/auth/auth-guard';
import UnAuthGuard from '~modules/auth/un-auth-guard';
import { UserRole } from '~modules/users/types/user-role-enum';
import Forbidden from './pages/Forbidden';
import PlayerSubstitution from './pages/PlayerSubstitution';

const Login = lazy(() => import('./pages/Login'));
const AuthCallback = lazy(() => import('./pages/AuthCallback'));
const MatchSignIn = lazy(() => import('./pages/MatchSignIn'));
const RecoverPassword = lazy(() => import('./pages/RecoverPassword'));
const MyAccount = lazy(() => import('./pages/MyAccount'));

export const accountRoutes = {
  login: {
    title: () => 'Sign In',
    path: (returnUrl?: string) => {
      const loginBasePath = '/login';
      return returnUrl ? addQueryParams(loginBasePath, { returnUrl }) : loginBasePath;
    },
    roles: [],
  },
  playerAuthentication: {
    title: () => 'Authenticate',
    path: () => '/play',
    roles: [UserRole.PLAYER],
  },
  matchSignIn: {
    title: () => 'Sign in to league match',
    path: () => '/play/match',
    roles: [UserRole.PLAYER],
  },
  privateMatchSignIn: {
    title: () => 'Sign in to a private match',
    path: (token?: string) => `/play/private-match/${token ? token : ':token'}`,
    roles: [UserRole.PLAYER],
  },
  playerSubstitution: {
    title: () => 'Substitute a player',
    path: (matchCode?: string) =>
      `/play/match/${matchCode ? matchCode : ':matchCode'}/substitute`,
    roles: [UserRole.PLAYER],
  },
  authCallback: {
    title: () => 'Authenticating...',
    path: () => '/auth-callback',
    roles: [],
  },
  forbidden: {
    title: () => 'Forbidden',
    path: () => '/403',
    roles: [],
  },
  myAccount: {
    title: () => 'My Account',
    path: () => '/my-account',
    roles: [],
  },
  recoverPassword: {
    title: () => 'Recover password',
    path: () => '/recover-password',
    roles: [],
  },
};

export function AccountRoutes(): RouteObject[] {
  function buildPrivateMatchSignInRedirect(): string | undefined {
    const res = matchPath({ path: accountRoutes.privateMatchSignIn.path() }, window.location.pathname);
    const params = res?.params;
    return accountRoutes.login.path(accountRoutes.privateMatchSignIn.path(params?.token));
  }

  return [
    {
      path: accountRoutes.login.path(),
      element: (<UnAuthGuard><Login /></UnAuthGuard>),
    },
    {
      path: accountRoutes.matchSignIn.path(),
      element: (
        <AuthGuard
          redirect={accountRoutes.login.path(accountRoutes.matchSignIn.path())}
          roles={accountRoutes.matchSignIn.roles}>
          <MatchSignIn />
        </AuthGuard>
      ),
    },
    {
      path: accountRoutes.privateMatchSignIn.path(),
      element: (
        <AuthGuard
          redirect={buildPrivateMatchSignInRedirect()}
          roles={accountRoutes.privateMatchSignIn.roles}>
          <MatchSignIn />
        </AuthGuard>
      ),
    },
    {
      path: accountRoutes.playerSubstitution.path(),
      element: (
        <AuthGuard
          redirect={accountRoutes.playerAuthentication.path()}
          roles={accountRoutes.playerSubstitution.roles}>
          <PlayerSubstitution />
        </AuthGuard>
      ),
    },
    {
      path: accountRoutes.authCallback.path(),
      element: <AuthCallback />,
    },
    {
      path: accountRoutes.forbidden.path(),
      element: <Forbidden />,
    },
    {
      path: accountRoutes.recoverPassword.path(),
      element: <UnAuthGuard><RecoverPassword /></UnAuthGuard>,
    },
    {
      path: accountRoutes.myAccount.path(),
      element: (
        <AuthGuard roles={accountRoutes.myAccount.roles}>
          <MyAccount title={accountRoutes.myAccount.title()} />
        </AuthGuard>
      ),
    },
  ];
}
