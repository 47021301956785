import { gql } from "@apollo/client";

export default gql(`
  mutation UpsertSubstitutionRequest(
    $payload: substitutions_requests_insert_input!
  ) {
    insert_substitutions_requests_one(
      object: $payload
      on_conflict: {
        constraint: substitutions_requests_pkey
        update_columns: [player_id, substitute_player_id, status]
      }
    ) {
      id
      player_id
      substitute_player_id
      match_id
      match {
        id
        code
        substitutions
      }
      status
      updated_at
      created_at
    }
  }
`);
