import { gql } from '@apollo/client';

export default gql`
  query GetAllUsers($withPhoto: Boolean!) {
    users(
      where: { deleted: { _eq: false } },
      order_by: { first_name: asc, last_name: asc }
    ) {
      id
      first_name
      last_name
      photo_url @include(if: $withPhoto)
      phone
      email
      address
      city
      state
      zip
      is_returning
      role
      status
    }
  }
`;
