import { gql } from '@apollo/client';
import { UserRole } from '~modules/users/types/user-role-enum';

export default gql`
  query GetAllAdminUsers($withPhoto: Boolean!) {
    users(
      where: {
        deleted: { _eq: false }
        role: { _in: [${UserRole.ADMIN}] }
      }
      order_by: { first_name: asc, last_name: asc }
    ) {
      id
      first_name
      last_name
      photo_url @include(if: $withPhoto)
      phone
      email
      address
      city
      state
      zip
      is_returning
      role
      status
    }
  }
`;
