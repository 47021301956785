import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import AuthGuard from '~modules/auth/auth-guard';
import { UserRole } from '~modules/users/types/user-role-enum';

const Players = lazy(() => import('./pages/Players'));
const EditPlayer = lazy(() => import('./pages/EditPlayer'));
const AddPlayer = lazy(() => import('./pages/AddPlayer'));

export const playersRoutes = {
  list: {
    title: () => 'Players',
    path: () => '/players',
    roles: [UserRole.ADMIN],
  },
  add: {
    title: () => 'Add Player',
    path: () => '/players/add',
    roles: [UserRole.ADMIN],
  },
  edit: {
    title: () => 'Edit Player',
    path: (id?: string) => `/players/${id ? id : ':id'}`,
    roles: [UserRole.ADMIN],
  },
};

export function PlayersRoutes(): RouteObject[] {
  return [
    {
      path: playersRoutes.list.path(),
      element: (
        <AuthGuard roles={playersRoutes.list.roles}>
          <Players title={playersRoutes.list.title()} />
        </AuthGuard>
      ),
    },
    {
      path: playersRoutes.add.path(),
      element: (
        <AuthGuard roles={playersRoutes.add.roles}>
          <AddPlayer title={playersRoutes.add.title()} />
        </AuthGuard>
      ),
    },
    {
      path: playersRoutes.edit.path(),
      element: (
        <AuthGuard roles={playersRoutes.edit.roles}>
          <EditPlayer />
        </AuthGuard>
      ),
    },
  ];
}
