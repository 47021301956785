import { ApolloClient } from '@apollo/client';
import {
  SUBSTITUTE_PLAYER_ON_MATCH,
  UPSERT_SUBSTITUTION_REQUEST,
} from '../graphql/mutations';
import { GET_SUBSTITUTION_REQUEST_BY_ID } from '../graphql/queries';
import { MatchSubstitution } from '../types/MatchSubstitution';
import { SubstitutionRequestStatus } from '../types/substitution-request-status-enum';
import { SubstitutionRequest } from '../types/SubstitutionRequest';

export const APPROVE_SUBSTITUTION_ERROR_MESSAGE = {
  missingPlayerIdOrSubstitutionId:
    'The player ID or substitute player ID is undefined',
  substitutionRequestNotFound: (id: string) =>
    `Substitution request ${id} not found.`,
};

function filterOutPastSubstitutions(
  substitutions: MatchSubstitution[],
  substituteId: string
) {
  return substitutions.filter((s) => s.player_id !== substituteId);
}

function substituteIsTheOriginalPlayer(
  substitutions: MatchSubstitution[],
  substituteId: string
) {
  return substitutions.find((s) => s.player_id === substituteId);
}

function updateSubstitutions(
  substitutions: MatchSubstitution[],
  playerId: string,
  substituteId: string
) {
  if (substituteIsTheOriginalPlayer(substitutions, substituteId)) {
    return filterOutPastSubstitutions(substitutions, substituteId);
  } else {
    const newSubstitution: MatchSubstitution = {
      player_id: playerId,
      substitute_id: substituteId,
      created_at: new Date().toISOString(),
    };
    return [...substitutions, newSubstitution];
  }
}

export async function approveMatchSubstitution(
  apolloClient: ApolloClient<any>,
  options: {
    substitutionRequestId?: string;
    matchId?: string;
    substitutions?: MatchSubstitution[];
    playerId?: string;
    substitutePlayerId?: string;
  }
): Promise<SubstitutionRequest> {
  if (options?.substitutionRequestId) {
    const { data } = await apolloClient.query({
      query: GET_SUBSTITUTION_REQUEST_BY_ID,
      variables: {
        id: options.substitutionRequestId,
      },
    });

    const substitutionRequest = data?.substitutions_requests_by_pk;

    if (!substitutionRequest)
      throw new Error(
        APPROVE_SUBSTITUTION_ERROR_MESSAGE.substitutionRequestNotFound(
          options.substitutionRequestId
        )
      );

    const {
      player_id,
      substitute_player_id,
      match_id,
      match,
    } = substitutionRequest;
    options.substitutions = match?.substitutions || [];
    options.matchId = match_id;
    options.playerId = player_id;
    options.substitutePlayerId = substitute_player_id;
  }

  if (!options.playerId || !options.substitutePlayerId)
    throw new Error(
      APPROVE_SUBSTITUTION_ERROR_MESSAGE.missingPlayerIdOrSubstitutionId
    );

  let updatedSubstitutions = updateSubstitutions(
    options.substitutions || [],
    options.playerId,
    options.substitutePlayerId
  );

  await apolloClient.mutate({
    mutation: SUBSTITUTE_PLAYER_ON_MATCH,
    variables: {
      match_id: options.matchId,
      player_id: options.playerId,
      substitute_player_id: options.substitutePlayerId,
      substitutions: updatedSubstitutions,
    },
  });

  const { data } = await apolloClient.mutate({
    mutation: UPSERT_SUBSTITUTION_REQUEST,
    variables: {
      payload: {
        ...(options?.substitutionRequestId
          ? { id: options?.substitutionRequestId }
          : {}),
        player_id: options?.playerId,
        substitute_player_id: options?.substitutePlayerId,
        match_id: options?.matchId,
        status: SubstitutionRequestStatus.APPROVED,
      },
    },
  });

  return data?.insert_substitutions_requests_one as SubstitutionRequest;
}
