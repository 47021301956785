import { SpinnerLoader } from './SpinnerLoader';

interface LoaderProps {
  label?: string;
  description?: string;
}

function CenterLoader({ label, description }: LoaderProps) {
  return (
    <div className="absolute inset-0 h-screen flex flex-col justify-center items-center text-center">
      <SpinnerLoader className={'text-primary mb-6'} size={3} />
      <h3 className={'mb-0 font-bold'}>{label || 'Please wait'}</h3>
      {!!description && <h4>{description}</h4>}
    </div>
  );
}

export default CenterLoader;
