import { ApolloClient, gql } from '@apollo/client';

export async function isTournamentMatch(
  apolloClient: ApolloClient<any>,
  matchId: string
): Promise<boolean> {
  if (!matchId) throw new Error('Param matchId is required');
  const { data } = await apolloClient.query({
    query: gql`
      query IsTournamentMatchQuery($match_id: uuid!) {
        tournament_matches_aggregate(where: { match_id: { _eq: $match_id } }) {
          aggregate {
            count
          }
        }
      }
    `,
    variables: {
      match_id: matchId,
    },
  });

  return (data?.tournament_matches_aggregate?.aggregate?.count || 0) > 0;
}
