import { RootRoute } from './Route';

export function parseRoutesToArray(routes: Array<RootRoute>) {
  let allRoutes = routes.map((route) =>
    Object.keys(route).map((key) => ({
      ...route[key],
    }))
  );

  if (!allRoutes.length) return [];
  return allRoutes.reduce((a, b) => a.concat(b));
}
