import { gql } from '@apollo/client';

export default gql`
  query CountMatchGamesAttempts($match_id: uuid!) {
    attempts_aggregate(where: { game: { match_id: { _eq: $match_id } } }) {
      aggregate {
        count
      }
    }
  }
`;
