export enum SportType {
  DART = 'dart',
  CYBER_ALLEY = 'cyber_alley',
}

export function getSportLabel(sport?: SportType) {
  if (!sport) return '';
  switch (sport) {
    case SportType.DART:
      return 'Medalist Darts';

    case SportType.CYBER_ALLEY:
      return 'Cyber Alley';

    default:
      return '';
  }
}

export function getSportShortLabel(sport?: SportType) {
  if (!sport) return '';
  switch (sport) {
    case SportType.DART:
      return 'Darts';

    case SportType.CYBER_ALLEY:
      return 'Cyber Alley';

    default:
      return '';
  }
}
