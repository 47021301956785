import { gql } from '@apollo/client';

export default gql`
  query CountLeagueGamesAttempts($league_id: uuid!) {
    attempts_aggregate(
      where: {
        game: { match: { division: { league_id: { _eq: $league_id } } } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
