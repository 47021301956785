import { gql } from '@apollo/client';

/*
mutation update_game_teams_players substitute the player
mutation update_matches_by_pk is used to update the substitutions property
mutation update_games is used to update the updated_at property, then the cabinet can pull the new data
*/

export default gql`
  mutation SubstitutePlayerOnMatch(
    $match_id: uuid!
    $player_id: uuid!
    $substitute_player_id: uuid!
    $substitutions: json!
  ) {
    update_game_teams_players(
      where: {
        _and: [
          { player_id: { _eq: $player_id } }
          { game_team: { game: { match_id: { _eq: $match_id } } } }
        ]
      }
      _set: { player_id: $substitute_player_id }
    ) {
      affected_rows
      returning {
        game_team {
          game {
            id
            match {
              id
              code
            }
          }
        }
        game_team_id
        player_id
      }
    }

    update_games(
      where: { match_id: { _eq: $match_id } }
      _set: { match_id: $match_id }
    ) {
      affected_rows
      returning {
        id
        updated_at
      }
    }

    update_matches_by_pk(
      pk_columns: { id: $match_id }
      _set: { substitutions: $substitutions }
    ) {
      id
      code
      substitutions
      updated_at
    }
  }
`;
