import { useApolloClient, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { mdiTableSearch } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Lottie from 'react-lottie';
import { useNavigate, useParams } from 'react-router-dom';
import successAnimation from '~assets/lotties/success-animation.json';
import Button from '~components/buttons/Button';
import Table from '~components/content/Table';
import Checkbox from '~components/forms/Checkbox';
import CenterLoader from '~components/progress/CenterLoader';
import LoginLayout from '~layouts/LoginLayout';
import { getUserId } from '~modules/auth/auth-helpers';
import { GET_PLAYERS_BY_USER_ID } from '~modules/players/graphql/queries';
import { getPlayerName } from '~modules/players/services/get-player-name';
import { Player } from '~modules/players/types/Player';
import { GET_MATCHES_BY_CODE } from '~modules/schedule/graphql/queries';
import { playerIsAssignedToMatch } from '~modules/schedule/services/player-is-assigned-to-match';
import { substitutePlayerOnMatch } from '~modules/schedule/services/substitute-player-on-match';
import { Match } from '~modules/schedule/types/Match';
import { SubstitutionRequest } from '~modules/schedule/types/SubstitutionRequest';
import { SubstitutionRequestStatus } from '~modules/schedule/types/substitution-request-status-enum';
import { getMatchPlayers } from '~modules/teams/services/get-match-players';
import colors from '~styles/colors';
import { accountRoutes } from '../routes';

export interface PlayerSubstitutionProps extends React.PropsWithChildren {}

const PlayerSubstitution: React.FC<PlayerSubstitutionProps> = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const { matchCode } = useParams<{ matchCode: string }>();
  const apolloClient = useApolloClient();
  const [done, setDone] = useState(false);
  const [substituting, setSubstituting] = useState(false);
  const [
    substitutionRequest,
    setSubstitutionRequest,
  ] = useState<SubstitutionRequest>();
  const [selectedPlayer, setSelectedPlayer] = useState<Player>();
  const [matchPlayers, setMatchPlayers] = useState<Player[]>([]);
  const currentUserId = user && getUserId(user);
  const {
    data: substitutePlayerData,
    loading: loadingSubstitutePlayer,
  } = useQuery(GET_PLAYERS_BY_USER_ID, {
    variables: {
      user_id: currentUserId,
    },
  });
  const { data, loading: loadingMatch } = useQuery(GET_MATCHES_BY_CODE, {
    fetchPolicy: 'network-only',
    variables: {
      code: matchCode?.substring(0, 4),
    },
  });
  const loading: boolean = loadingSubstitutePlayer || loadingMatch;
  const match: Match = (data?.matches || [])[0];
  const substitutePlayer = substitutePlayerData?.players[0];
  const playerIsAssigned = playerIsAssignedToMatch(match, substitutePlayer?.id);

  async function substitute(player?: Player) {
    try {
      const matchId = match?.id as string;
      const playerId = player?.id as string;
      const substituteId = substitutePlayer?.id!!;
      setSubstituting(true);
      const substitutionRequest = await substitutePlayerOnMatch(
        apolloClient,
        matchId,
        playerId,
        substituteId
      );
      setSubstitutionRequest(substitutionRequest);
      setDone(true);
      setSubstituting(false);
    } catch (err: any) {
      console.error(err);
      setSubstituting(false);
      toast(err?.message || 'Something went wrong, please try again later');
    }
  }

  function playerIsSelected(playerId: string) {
    return selectedPlayer?.id === playerId;
  }

  const loadMatchPlayers = useCallback(
    async (match: Match) => {
      const players = await getMatchPlayers(apolloClient, match);
      setMatchPlayers(players);
    },
    [apolloClient]
  );

  useEffect(() => {
    if (!match) return;
    loadMatchPlayers(match);
  }, [match, loadMatchPlayers]);

  return (
    <LoginLayout>
      <div className="text-center flex-grow flex flex-col justify-center">
        {loading && (
          <CenterLoader description={'Loading players to substitute'} />
        )}
        {done && (
          <div className="max-w-none sm:max-w-sm mx-auto">
            <div className={'mb-4'}>
              <Lottie
                options={{
                  animationData: successAnimation,
                  loop: false,
                  autoplay: true,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                width={120}
                height={120}
              />
            </div>
            {substitutionRequest?.status ===
              SubstitutionRequestStatus.APPROVED && (
              <>
                <h1>Substitution done</h1>
                <h4>
                  {getPlayerName(selectedPlayer)} has been substituted for you
                </h4>
              </>
            )}

            {substitutionRequest?.status !==
              SubstitutionRequestStatus.APPROVED && (
              <>
                <h1>Substitution request placed</h1>
                <h4>An administrator will analyze your request.</h4>
              </>
            )}
            <Button
              className={'mt-6'}
              primary
              stacked
              label={'Sign in to the match'}
              onClick={() => navigate(accountRoutes.matchSignIn.path())}
            />
          </div>
        )}
        {!done && !loading && !substituting && playerIsAssigned && (
          <div className="max-w-none sm:max-w-sm mx-auto">
            <h1>You are already assigned to this match</h1>
            <h4>
              You cannot substitute another player, because you already assigned
              to this match
            </h4>
          </div>
        )}
        {!done && !loading && !match && (
          <div className="flex flex-col items-center justify-center">
            <div>
              <Icon path={mdiTableSearch} size={3} color={colors.primary} />
            </div>
            <div className={'flex flex-col items-center'}>
              <h1>Match not found</h1>
              <h4>The match with code {matchCode} was not found.</h4>
            </div>
          </div>
        )}
        {!done && !loading && !substitutePlayer && (
          <div className="max-w-none sm:max-w-sm mx-auto">
            <h1>You are not a player</h1>
            <h4>
              Only users with a player role can substitute another player.
            </h4>
          </div>
        )}
        {!done &&
          !loading &&
          !!match &&
          !!substitutePlayer &&
          !playerIsAssigned && (
            <div className="max-w-none sm:max-w-sm mx-auto">
              <h1>Which player do you want to substitute?</h1>
              <div className={'mt-8'}>
                <Table>
                  <tbody>
                    {matchPlayers?.map((player: Player, index: number) => (
                      <tr
                        className={'cursor-pointer'}
                        key={index}
                        onClick={() => setSelectedPlayer(player)}
                      >
                        <td style={{ width: '100%' }} className="text-left">
                          {getPlayerName(player)}
                        </td>
                        <td className={'text-right'}>
                          <Checkbox
                            checked={playerIsSelected(player?.id as string)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Button
                  primary
                  stacked
                  label={substituting ? 'Substituting...' : 'Substitute'}
                  disabled={substituting || !selectedPlayer}
                  onClick={() => substitute(selectedPlayer)}
                />
              </div>
            </div>
          )}
      </div>
    </LoginLayout>
  );
};

export default PlayerSubstitution;
