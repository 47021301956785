import { gql } from "@apollo/client";

export default gql(`
  query GetSubstitutionRequestById($id: uuid!) {
    substitutions_requests_by_pk(id: $id) {
      id
      player_id
      substitute_player_id
      match_id
      match {
        id
        code
        substitutions
      }
      status
      created_at
      updated_at
    }
  }
`);
