import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import AuthGuard from '~modules/auth/auth-guard';
import { UserRole } from '~modules/users/types/user-role-enum';

const Operators = lazy(() => import('./pages/Operators'));
const AddOperator = lazy(() => import('./pages/AddOperator'));
const EditOperator = lazy(() => import('./pages/EditOperator'));

export const operatorsRoutes = {
  home: {
    title: () => 'Operators',
    path: () => '/operators',
    roles: [UserRole.ADMIN],
  },
  add: {
    title: () => 'Add New Operator',
    path: () => '/operators/add',
    roles: [UserRole.ADMIN],
  },
  edit: {
    title: () => 'Edit Operator',
    path: (id?: string) => `/operators/${id ? id : ':id'}`,
    roles: [UserRole.ADMIN],
  },
};

export function OperatorsRoutes(): RouteObject[] {
  return [
    {
      path: operatorsRoutes.home.path(),
      element: (
        <AuthGuard roles={operatorsRoutes.home.roles}>
          <Operators title={operatorsRoutes.home.title()} />
        </AuthGuard>
      ),
    },
    {
      path: operatorsRoutes.add.path(),
      element: (
        <AuthGuard roles={operatorsRoutes.add.roles}>
          <AddOperator title={operatorsRoutes.add.title()} />
        </AuthGuard>
      ),
    },
    {
      path: operatorsRoutes.edit.path(),
      element: (
        <AuthGuard roles={operatorsRoutes.edit.roles}>
          <EditOperator title={operatorsRoutes.edit.title()} />
        </AuthGuard>
      ),
    },
  ]
}
