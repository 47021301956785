import { PrimeReactProvider } from 'primereact/api';
import { Suspense } from 'react';
import { twMerge } from 'tailwind-merge';
import Snackbar from '~components/notifications/Snackbar';
import SplashScreen from '~layouts/SplashScreen';
import AppRoutes from '~router/routes';
import { GlobalStyles } from '~styles/index';
import '~styles/index.css';
import PrimeReactStyles from './PrimeReactStyles';

function App() {
  return (<>
    <GlobalStyles />
    <PrimeReactProvider value={{ unstyled: true, pt: PrimeReactStyles, ptOptions: { mergeSections: true, mergeProps: true, classNameMergeFunction: twMerge } }}>
      <Suspense fallback={<SplashScreen />}>
        <AppRoutes />
        <Snackbar />
      </Suspense>
    </PrimeReactProvider>
  </>);
}

export default App;
