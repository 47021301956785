import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Navigate, Outlet, RouterProvider, createBrowserRouter, useLocation } from 'react-router-dom';
import { ModalProvider } from '~components/modals/Modal';
import SplashScreen from '~layouts/SplashScreen';
import { AccountRoutes, accountRoutes } from '~modules/account/routes';
import AuthGuard from '~modules/auth/auth-guard';
import { Auth0ProviderWithNavigate } from '~modules/auth/auth0-provider-with-navigate';
import { CampaignsRoutes, campaignsRoutes } from '~modules/campaigns/routes';
import { DartsRoutes, dartsRoutes } from '~modules/darts/routes';
import { DashboardRoutes, dashboardRoutes } from '~modules/dashboard/routes';
import ApolloClientProvider from '~modules/graphql/apollo-client-provider';
import { HardwareRoutes, hardwareRoutes } from '~modules/hardware/routes';
import { LocationsRoutes, locationsRoutes } from '~modules/locations/routes';
import NSApiAxiosClientInitializer from '~modules/ns-api/ns-api-axios-configurer';
import { OperatorsRoutes, operatorsRoutes } from '~modules/operators/routes';
import { PlayersRoutes, playersRoutes } from '~modules/players/routes';
import { TeamsRoutes, teamsRoutes } from '~modules/teams/routes';
import { TournamentsRoutes, tournamentsRoutes } from '~modules/tournaments/routes';
import { UsersRoutes, usersRoutes } from '~modules/users/routes';
import { getInitialRouteForUser } from './get-initial-route';
import { parseRoutesToArray } from './parse-routes-to-array';

export const allRoutes = parseRoutesToArray([
  dartsRoutes,
  accountRoutes,
  dashboardRoutes,
  hardwareRoutes,
  locationsRoutes,
  campaignsRoutes,
  operatorsRoutes,
  playersRoutes,
  teamsRoutes.admin,
  teamsRoutes.player,
  usersRoutes,
  tournamentsRoutes,
]);

const InitialRoute = () => {
  const { user } = useAuth0();
  const route = getInitialRouteForUser(user);

  return <AuthGuard> <Navigate to={route} /> </AuthGuard>
};

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    element: (
      <Auth0ProviderWithNavigate>
        <NSApiAxiosClientInitializer />
        <ApolloClientProvider>
          <ScrollToTopOnLocationChange />
          <ModalProvider>
            <Outlet />
          </ModalProvider>
        </ApolloClientProvider>
      </Auth0ProviderWithNavigate>
    ),
    children: [
      {
        index: true,
        Component: InitialRoute,
      },
      ...DashboardRoutes(),
      ...DartsRoutes(),
      ...TournamentsRoutes(),
      ...OperatorsRoutes(),
      ...PlayersRoutes(),
      ...TeamsRoutes(),
      ...LocationsRoutes(),
      ...CampaignsRoutes(),
      ...HardwareRoutes(),
      ...UsersRoutes(),
      ...AccountRoutes(),
    ],
  }

]);

function ScrollToTopOnLocationChange() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function AppRoutes() {
  return <RouterProvider router={router} fallbackElement={<SplashScreen />} />;
}

AppRoutes.propTypes = { children: PropTypes.any };

export default AppRoutes;
