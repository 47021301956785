import React from 'react';
import { Toaster } from 'react-hot-toast';

const Snackbar: React.FC = () => {
  return (
    <Toaster
      position={'bottom-center'}
      toastOptions={{
        className: 'bg-gray-600 text-white max-w-md z-50'
      }}
    />
  );
};

export default Snackbar;
