import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import AuthGuard from '~modules/auth/auth-guard';
import { UserRole } from '~modules/users/types/user-role-enum';

const Locations = lazy(() => import('./pages/Locations'));
const AddLocation = lazy(() => import('./pages/AddLocation'));
const EditLocation = lazy(() => import('./pages/EditLocation'));

export const locationsRoutes = {
  home: {
    title: () => 'Locations',
    path: () => '/locations',
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  add: {
    title: () => 'Add New Location',
    path: () => '/locations/add',
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  edit: {
    title: () => 'Edit Location',
    path: (id?: string) => `/locations/${id ? id : ':id'}`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
};

export function LocationsRoutes(): RouteObject[] {
  return [
    {
      path: locationsRoutes.home.path(),
      element: (
        <AuthGuard roles={locationsRoutes.home.roles}>
          <Locations title={locationsRoutes.home.title()} />
        </AuthGuard>
      ),
    },
    {
      path: locationsRoutes.add.path(),
      element: (
        <AuthGuard roles={locationsRoutes.add.roles}>
          <AddLocation title={locationsRoutes.add.title()} />
        </AuthGuard>
      ),
    },
    {
      path: locationsRoutes.edit.path(),
      element: (
        <AuthGuard roles={locationsRoutes.edit.roles}>
          <EditLocation title={locationsRoutes.edit.title()} />
        </AuthGuard>
      ),
    },
  ];
}
