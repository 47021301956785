import { useAuth0 } from '@auth0/auth0-react';
import axios, { InternalAxiosRequestConfig } from "axios";
import { ReactElement, useEffect } from "react";
import { useLogout } from '~hooks/useLogout';

function customValidateStatus(status: number) {
  return status >= 200 && status < 500;
}

function isSameOriginRequest(config: InternalAxiosRequestConfig<any>) {
  if (config.baseURL && config.baseURL !== window.location.origin) {
    return false;
  }

  if (!config.url) {
    return false;
  }

  const url = new URL(config.url, window.location.origin);

  if (url.origin !== window.location.origin) {
    return false;
  }

  return true;
}

function useCustomValidateStatus() {
  useEffect(() => {
    axios.defaults.validateStatus = customValidateStatus;
  }, []);
}

function useSuccessResponseInterceptor() {
  useEffect(() => {
    const handle = axios.interceptors.response.use(response => {
      if (!isSameOriginRequest(response.config)) {
        return response;
      }

      if (!response.data) {
        response.data = {};
      } else if (typeof response.data !== 'object') {
        response.data = { value: response.data };
      }

      response.data.success = response.status === 200 && response.data.error == null;
      return response;
    });

    return () => axios.interceptors.response.eject(handle);
  }, []);
}

function useAccessTokenInterceptor() {
  const { getAccessTokenSilently } = useAuth0();
  const logout = useLogout();

  useEffect(() => {
    const getTokenSafe = async function () {
      try {
        return await getAccessTokenSilently();
      } catch (error) {
        console.error('Error refreshing token for axios api call ', error);
        return undefined;
      }
    };

    const handle = axios.interceptors.request.use(async config => {
      if (!isSameOriginRequest(config)) {
        return config;
      }

      if (config.headers.hasAuthorization()) {
        return config;
      }

      const token = await getTokenSafe();
      if (!token) {
        logout();
        throw Error('Not authorized');
      }

      config.headers.setAuthorization('Bearer ' + token);
      return config;
    });

    return () => axios.interceptors.request.eject(handle);
  }, [getAccessTokenSilently, logout]);
}

export default function NSApiAxiosClientInitializer(): ReactElement {
  useCustomValidateStatus();
  useSuccessResponseInterceptor();
  useAccessTokenInterceptor();

  return <></>;
}
