const styled = require('styled-components');
const { default: tw } = require('twin.macro');

const FormControlsGlobalStyle = styled.createGlobalStyle`
  input,
  select {
    ${tw`bg-white disabled:(bg-gray-100 text-gray-500)`}
  }
`;

export default FormControlsGlobalStyle;
