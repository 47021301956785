import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import AuthGuard from '~modules/auth/auth-guard';
import { FlightEnum } from '~modules/flights/fixtures/flights';
import { UserRole } from '~modules/users/types/user-role-enum';

const Tournaments = lazy(() => import('./pages/Tournaments'));
const TournamentDetails = lazy(() => import('./pages/TournamentDetails'));
const EditTournament = lazy(() => import('./pages/EditTournament'));
const ReviewLeagueRanking = lazy(() => import('./pages/ReviewLeagueRanking'));
const ReviewTournamentBrackets = lazy(() =>
  import('./pages/ReviewTournamentBrackets')
);

export const tournamentsRoutes = {
  list: {
    title: () => 'Tournaments',
    path: () => '/tournaments',
    roles: [UserRole.ADMIN],
  },
  edit: {
    title: () => 'Edit Tournament',
    path: (id?: string) => `/tournaments/${id ? id : ':id'}/edit`,
    roles: [UserRole.ADMIN],
  },
  details: {
    title: () => 'Tournament board',
    path: (id?: string) => `/tournaments/${id ? id : ':id'}`,
    roles: [UserRole.ADMIN],
  },
  ranking: {
    title: () => 'League ranking',
    path: (leagueId?: string, flight?: FlightEnum) =>
      `/tournaments/league/${leagueId || ':leagueId'}/ranking/${flight || ':flight'}`,
    roles: [UserRole.ADMIN],
  },
  preview: {
    title: () => 'Preview tournament',
    path: (leagueId?: string, flight?: string) =>
      `/tournaments/league/${leagueId || ':leagueId'}/preview/${flight || ':flight'}`,
    roles: [UserRole.ADMIN],
  },
};

export function TournamentsRoutes(): RouteObject[] {
  return [
    {
      path: tournamentsRoutes.list.path(),
      element: (
        <AuthGuard roles={tournamentsRoutes.list.roles}>
          <Tournaments title={tournamentsRoutes.list.title()} />
        </AuthGuard>
      ),
    },
    {
      path: tournamentsRoutes.edit.path(),
      element: (
        <AuthGuard roles={tournamentsRoutes.edit.roles}>
          <EditTournament title={tournamentsRoutes.edit.title()} />
        </AuthGuard>
      ),
    },
    {
      path: tournamentsRoutes.details.path(),
      element: (
        <AuthGuard roles={tournamentsRoutes.details.roles}>
          <TournamentDetails title={tournamentsRoutes.details.title()} />
        </AuthGuard>
      ),
    },
    {
      path: tournamentsRoutes.ranking.path(),
      element: (
        <AuthGuard roles={tournamentsRoutes.ranking.roles}>
          <ReviewLeagueRanking
            title={tournamentsRoutes.ranking.title()}
          />
        </AuthGuard>
      ),
    },
    {
      path: tournamentsRoutes.preview.path(),
      element: (
        <AuthGuard roles={tournamentsRoutes.preview.roles}>
          <ReviewTournamentBrackets
            title={tournamentsRoutes.preview.title()}
          />
        </AuthGuard>
      ),
    },
  ];
}
