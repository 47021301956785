import { uniqueId } from 'lodash';
import React, { Context, useCallback, useState } from 'react';

export type ModalContextType = {
  openModal: (options?: ModalOptions) => void;
  closeModal: (id?: string) => void;
};

const ModalContext: Context<ModalContextType> = React.createContext({
  openModal: () => { },
  closeModal: () => { },
});

export interface ModalOptions extends React.PropsWithChildren {
  id?: string;
  content?: JSX.Element;
}

const Modal = ({ children }: React.PropsWithChildren) => (
  <div className='fixed inset-0 overflow-y-auto z-[9999]'>
    <div className="flex flex-col items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div className={`fixed inset-0 transition-opacity`} aria-hidden="true">
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      {/* This element is to trick the browser into centering the modal contents. */}
      <span
        className="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      >
        &#8203;
      </span>
      {/* This element is to trick the browser into centering the modal contents. */}
      {children}
    </div>
  </div>
);

export interface ModalProviderProps extends React.PropsWithChildren { }

const ModalProvider = ({ children }: React.PropsWithChildren) => {
  const [modals, setModals] = useState<ModalOptions[]>([]);

  const openModal = useCallback((options?: ModalOptions) => {
    setModals(modals => [
      ...modals,
      {
        ...options,
        id: options?.id || uniqueId(),
      },
    ]);
  }, []);

  const closeModal = useCallback((id?: string) => {
    setModals(modals =>
      id != null
        ? modals.filter(modal => modal.id !== id)
        : modals.slice(0, -1)
    );
  }, []);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}

      {modals.map((modal, index) => (
        <Modal key={index}>
          {modal?.content}
        </Modal>
      ))}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
