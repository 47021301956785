import ColorsGlobalStyle from './ColorsGlobalStyle';
import FormControlsGlobalStyle from './FormControlsGlobalStyle';
import TypographyGlobalStyle from './TypographyGlobalStyle';

export const GlobalStyles = () => {
  return (
    <>
      <ColorsGlobalStyle />
      <TypographyGlobalStyle />
      <FormControlsGlobalStyle />
    </>
  );
};
