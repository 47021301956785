import { gql } from '@apollo/client';

export default gql`
  query GetPlayersByDisplayNames($names: [String!]) {
    players(where: { user: { display_name: { _in: $names } } }) {
      id
      rating
      cricket_rating
      ohone_rating
      user_id
      user {
        id
        first_name
        last_name
        display_name
        photo_url
        phone
        email
        address
        city
        state
        zip
        role
        status
      }
    }
  }
`;
