import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import AuthGuard from '~modules/auth/auth-guard';
import { UserRole } from '~modules/users/types/user-role-enum';

const AutoRegisterHardware = lazy(() => import('./pages/AutoRegisterHardware'));
const RegisterHardware = lazy(() => import('./pages/RegisterHardware'));
const Hardwares = lazy(() => import('./pages/Hardware'));
const HardwareCredentials = lazy(() => import('./pages/HardwareCredentials'));
const AddHardwareCredential = lazy(() =>
  import('./pages/AddHardwareCredential')
);
const EditHardwareCredential = lazy(() =>
  import('./pages/EditHardwareCredential')
);
const AddHardware = lazy(() => import('./pages/AddHardware'));
const EditHardware = lazy(() => import('./pages/EditHardware'));
const ViewHardwareStats = lazy(() => import('./pages/ViewHardwareStats'));
const HardwareLedger = lazy(() => import('./pages/HardwareLedger'));

export const hardwareRoutes = {
  home: {
    title: () => 'Hardware',
    path: () => '/hardware',
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  add: {
    title: () => 'Add New Hardware',
    path: () => '/hardware/add',
    roles: [UserRole.ADMIN],
  },
  autoRegisterHardware: {
    title: () => 'Auto Register Hardware',
    path: () => '/hardware/register/:token',
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  edit: {
    title: () => 'Edit Hardware',
    path: (id?: string) => `/hardware/${id ? id : ':id'}`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  stats: {
    title: () => 'Hardware Stats',
    path: (id?: string) => `/hardware/${id ? id : ':id'}/stats`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  credentials: {
    title: () => 'Hardware credentials',
    path: () => `/hardware/credentials`,
    roles: [UserRole.ADMIN],
  },
  addCredentials: {
    title: () => 'Add new Hardware credential',
    path: () => `/hardware/credentials/add`,
    roles: [UserRole.ADMIN],
  },
  editCredentials: {
    title: () => 'Edit Hardware credential',
    path: (code?: string) => `/hardware/credentials/${code ? code : ':code'}`,
    roles: [UserRole.ADMIN],
  },
  ledger: {
    title: () => 'Hardware Ledger',
    path: (id?: string) => `/hardware/${id ? id : ':hardwareId'}/ledger`,
    roles: [UserRole.ADMIN],
  },
  cabinetRegistration: {
    title: () => 'Register Cabinet',
    path: () => '/cabinet/register',
    roles: [UserRole.ADMIN],
  },
};

export function HardwareRoutes(): RouteObject[] {
  return [
    {
      path: hardwareRoutes.cabinetRegistration.path(),
      element: (
        <AuthGuard roles={hardwareRoutes.cabinetRegistration.roles}>
          <RegisterHardware />
        </AuthGuard>
      ),
    },
    {
      path: hardwareRoutes.autoRegisterHardware.path(),
      element: (
        <AuthGuard roles={hardwareRoutes.autoRegisterHardware.roles}>
          <AutoRegisterHardware />
        </AuthGuard>
      ),
    },
    {
      path: hardwareRoutes.credentials.path(),
      element: (
        <AuthGuard roles={hardwareRoutes.credentials.roles}>
          <HardwareCredentials title={hardwareRoutes.credentials.title()} />
        </AuthGuard>
      ),
    },
    {
      path: hardwareRoutes.addCredentials.path(),
      element: (
        <AuthGuard roles={hardwareRoutes.addCredentials.roles}>
          <AddHardwareCredential title={hardwareRoutes.addCredentials.title()} />
        </AuthGuard>
      ),
    },
    {
      path: hardwareRoutes.editCredentials.path(),
      element: (
        <AuthGuard roles={hardwareRoutes.editCredentials.roles}>
          <EditHardwareCredential title={hardwareRoutes.editCredentials.title()} />
        </AuthGuard>
      ),
    },
    {
      path: hardwareRoutes.home.path(),
      element: (
        <AuthGuard roles={hardwareRoutes.home.roles}>
          <Hardwares title={hardwareRoutes.home.title()} />
        </AuthGuard>
      ),
    },
    {
      path: hardwareRoutes.add.path(),
      element: (
        <AuthGuard roles={hardwareRoutes.add.roles}>
          <AddHardware title={hardwareRoutes.add.title()} />
        </AuthGuard>
      ),
    },
    {
      path: hardwareRoutes.edit.path(),
      element: (
        <AuthGuard roles={hardwareRoutes.edit.roles}>
          <EditHardware title={hardwareRoutes.edit.title()} />
        </AuthGuard>
      ),
    },
    {
      path: hardwareRoutes.stats.path(),
      element: (
        <AuthGuard roles={hardwareRoutes.stats.roles}>
          <ViewHardwareStats />
        </AuthGuard>
      ),
    },
    {
      path: hardwareRoutes.ledger.path(),
      element: (
        <AuthGuard roles={hardwareRoutes.ledger.roles}>
          <HardwareLedger />
        </AuthGuard>
      ),
    },
  ];
}
