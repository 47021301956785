
import { PrimeReactPTOptions } from 'primereact/api';
import Tailwind from 'primereact/passthrough/tailwind';
import { classNames } from 'primereact/utils';

const PrimeReactStyles: PrimeReactPTOptions = {
  ...Tailwind,
  calendar: {
    ...Tailwind.calendar,
    root(options) {
      return {
        className: classNames('inline-flex relative w-full'),
      };
    },
    input: {
      root(options) {
        return {
          className: classNames(
            'inline-flex w-full border hover:border-inherit rounded-md bg-white text-gray-600 focus:outline-none focus:border-primary focus:shadow-none', {
            'bg-gray-100 text-gray-500': options?.props.disabled,
          }),
        };
      },
    },
    transition: undefined,
  },
  multiselect: {
    ...Tailwind.multiselect,
    root(options) {
      return {
        className: classNames(
          'inline-flex cursor-pointer select-none w-full border rounded-md bg-white text-gray-600', {
          'bg-gray-100 text-gray-500': options?.props.disabled,
          'outline-none border-primary': options?.state.focused,
          '!border !border-red-500': options?.props?.['aria-invalid'] === 'true',
        })
      };
    },
    labelContainer(options) {
      return 'overflow-hidden flex flex-auto cursor-pointer';
    },
    label(options) {
      return {
        className: classNames(
          'block overflow-hidden cursor-pointer overflow-ellipsis',
          'dark:text-white/80',
          'p-3 transition duration-200', {
          '!p-3': options?.props.display !== 'chip' && options?.props.value == null,
          '!py-1.5 px-3': options?.props.display === 'chip' && options?.props.value !== null
        })
      };
    },
    transition: undefined
  },
};

export default PrimeReactStyles;
