import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import AuthGuard from '~modules/auth/auth-guard';
import { UserRole } from '~modules/users/types/user-role-enum';

const Dashboard = lazy(() => import('./pages/Dashboard'));

export const dashboardRoutes = {
  home: {
    title: () => 'Dashboard',
    path: () => '/dashboard',
    roles: [UserRole.ADMIN],
  },
};

export function DashboardRoutes(): RouteObject[] {
  return [
    {
      path: dashboardRoutes.home.path(),
      element: (
        <AuthGuard roles={dashboardRoutes.home.roles}>
          <Dashboard />
        </AuthGuard>
      ),
    },
  ];
}
