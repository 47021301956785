import { gql } from '@apollo/client';

export default gql`
  query GetUserByPk($id: uuid!) {
    users_by_pk(id: $id) {
      id
      first_name
      last_name
      display_name
      photo_url
      phone
      email
      address
      city
      state
      zip
      is_returning
      role
      status
      is_valid
      player {
        id
        display_id
        ohone_initial_avg_score
        ohone_avg_score
        cricket_initial_avg_score
        cricket_avg_score
      }
    }
  }
`;
