import { gql } from '@apollo/client';

export default gql`
  query GetAllPlayers {
    players(where: { user: { deleted: { _eq: false } } }, order_by: { user: { first_name: asc, last_name: asc } }) {
      id
      rating
      cricket_rating
      ohone_rating
      display_id
      notes {
        id
        created_at
        text
        player_id
      }
      user_id
      user {
        id
        first_name
        last_name
        display_name
        photo_url
        phone
        email
        address
        city
        state
        zip
        role
        status
      }
    }
  }
`;
