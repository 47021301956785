import { gql } from '@apollo/client';

export default gql`
  mutation UpdateMatch($id: uuid!, $payload: matches_set_input!) {
    update_matches_by_pk(_set: $payload, pk_columns: { id: $id }) {
      id
      code
      date
    }
  }
`;
