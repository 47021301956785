import { User } from '@auth0/auth0-react';
import { UserRole } from '~modules/users/types/user-role-enum';
import { UserRoleExtensions } from '~modules/users/types/user-role-extensions-enum';

const HASURA_ROLE_HEADER = 'x-hasura-role';
const HASURA_PLAYER_HEADER = 'x-hasura-player-id';

export function getUserId(user: User) {
  try {
    const claims = getHasuraClaims(user);
    return claims['x-hasura-user-id'] || user?.sub?.replace('auth0|', '');
  } catch (err) {
    return null;
  }
}

export function getPlayerId(user: User) {
  const claims = getHasuraClaims(user);
  return claims[HASURA_PLAYER_HEADER] as UserRole;
}

export function getUserRole(user: User): UserRole | undefined {
  const claims = getHasuraClaims(user);
  return claims[HASURA_ROLE_HEADER] as UserRole;
}

export function applyRoleHeader(role: UserRole | UserRoleExtensions) {
  return { [HASURA_ROLE_HEADER]: role };
}

export function getHasuraClaims(user: User) {
  return user['https://hasura.io/jwt/claims'] || {};
}
