import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import { getInitialRouteForUser } from "~router/get-initial-route";

export interface UnAuthGuardProps extends React.PropsWithChildren {
  redirect?: string;
};

const UnAuthGuard: React.FC<UnAuthGuardProps> = ({ redirect, children }: UnAuthGuardProps) => {
  const { isAuthenticated, user } = useAuth0();

  if (isAuthenticated) {
    const initialRoute = getInitialRouteForUser(user);
    return <Navigate to={redirect || initialRoute} />;
  }

  return <>{children}</>;
};

export default UnAuthGuard;
