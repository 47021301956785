import { useAuth0 } from '@auth0/auth0-react';
import Icon from '@mdi/react';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import { getUserRole } from '~modules/auth/auth-helpers';
import { UserRole } from '~modules/users/types/user-role-enum';
import nsLogo from '../../../assets/images/ns-logo.png';
import Button from '../buttons/Button';

const Wrapper = styled.div<{ $opened: boolean; $mobileRearrange: boolean; }>`
  ${({ $mobileRearrange }) => !$mobileRearrange && tw`h-screen`}

  @media (min-width: 640px) {
    width: ${props => (props.$opened ? 245 : 83)}px;
    transition: all 0.1s linear;
    ${tw`h-screen`}
  }
`;

export interface MainMenuProps extends React.PropsWithChildren {
  opened?: boolean;
  activeIndex?: number;
  items?: Array<MenuItemProps>;
  mobileRearrange?: boolean;
}

export interface MenuItemProps extends React.PropsWithChildren {
  label: string;
  iconPath: string;
  opened?: boolean;
  active?: () => boolean;
  path?: string;
  roles?: UserRole[];
  onClick?: () => any;
}

const MenuItem: React.FC<MenuItemProps> = ({
  iconPath,
  label,
  opened,
  active,
  path,
  onClick,
}) => {
  const Btn = () => (
    <Button
      $icon={<Icon size={1} path={iconPath} />}
      label={opened ? label : ''}
      primary={active && active()}
      stacked
      onClick={onClick}
    />
  );
  return path ? (
    <Link to={path} className="hover:no-underline">
      <Btn />
    </Link>
  ) : (
    <Btn />
  );
};

const MainMenu: React.FC<MainMenuProps> = ({ opened = false, items = [], mobileRearrange = false }) => {
  const [isOpen, setIsOpen] = useState(opened);

  const { user } = useAuth0();

  const userRole = user && getUserRole(user);

  useEffect(() => {
    setIsOpen(opened);
  }, [opened]);

  const menuItems = useMemo(() => {
    if (!items || !userRole) {
      return [];
    }

    return items
      .filter(({ roles }) => !roles?.length || (userRole && roles.includes(userRole)))
      .map((item: MenuItemProps, index: number) => (
        <MenuItem
          key={index}
          label={item.label}
          iconPath={item.iconPath}
          active={item.active}
          path={item.path}
          opened={isOpen}
          onClick={() => {
            item.onClick && item.onClick();
          }} />
      ));
  }, [items, userRole, isOpen]);

  return (
    <Wrapper
      className="bg-white border-r border-gray-200 p-4 w-full flex flex-row flex-col items-center z-10"
      $opened={isOpen}
      $mobileRearrange={mobileRearrange}
      onMouseEnter={() => (items?.length > 0 ? setIsOpen(true) : void 0)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className="flex-grow flex-row items-start justify-start w-full text-left flex-grow-0">
        <img
          src={nsLogo}
          alt={'Network Sports logo'}
          className="w-12 mx-auto sm:self-start"
        />
      </div>
      {!!menuItems?.length &&
        <div className="flex flex-grow w-full flex-col mt-20 gap-3">
          {menuItems}
        </div>
      }
    </Wrapper>
  );
};

export default MainMenu;
