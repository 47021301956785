import { ApolloClient } from '@apollo/client';
import { GET_PLAYER_BY_ID } from '~modules/players/graphql/queries';
import { UPSERT_SUBSTITUTION_REQUEST } from '../graphql/mutations';
import { GET_MATCH_BY_ID } from '../graphql/queries';
import { Match } from '../types/Match';
import { SubstitutionRequest } from '../types/SubstitutionRequest';
import { approveMatchSubstitution } from './approve-match-substitution';
import { isTournamentMatch } from './is-tournament-match';
import { matchHasGamesPlayed } from './match-has-games-played';

export const SUBSTITUTE_PLAYERS_ERRORS_MESSAGE = {
  userIsNotPlayer:
    'The substitute is not a player, only users with a player role can substitute.',
  matchNotFound: 'Match not found',
  matchAlreadyStarted: `Isn't allowed to substitute players on matches that already has games played`,
};

async function createSubstitutionRequest(
  apolloClient: ApolloClient<any>,
  matchId: string,
  playerUserId: string,
  substitutePlayerUserId: string
): Promise<SubstitutionRequest> {
  const { data } = await apolloClient.mutate({
    mutation: UPSERT_SUBSTITUTION_REQUEST,
    variables: {
      payload: {
        player_id: playerUserId,
        substitute_player_id: substitutePlayerUserId,
        match_id: matchId,
      },
    },
  });

  return data?.insert_substitutions_requests_one as SubstitutionRequest;
}

export async function substitutePlayerOnMatch(
  apolloClient: ApolloClient<any>,
  matchId: string,
  playerId: string,
  substitutePlayerId: string
): Promise<SubstitutionRequest> {
  const { data: substitutePlayerData } = await apolloClient.query({
    query: GET_PLAYER_BY_ID,
    variables: {
      id: substitutePlayerId,
    },
  });

  const substitutePlayer = substitutePlayerData?.players_by_pk;

  if (!substitutePlayer) {
    throw new Error(SUBSTITUTE_PLAYERS_ERRORS_MESSAGE.userIsNotPlayer);
  }

  const { data: matchData } = await apolloClient.query({
    query: GET_MATCH_BY_ID,
    variables: {
      id: matchId,
    },
  });

  const match: Match = matchData?.matches_by_pk;

  if (!match) {
    throw new Error(SUBSTITUTE_PLAYERS_ERRORS_MESSAGE.matchNotFound);
  }

  if (matchHasGamesPlayed(match)) {
    throw new Error(SUBSTITUTE_PLAYERS_ERRORS_MESSAGE.matchAlreadyStarted);
  }

  const substitutionRequest = await createSubstitutionRequest(
    apolloClient,
    matchId,
    playerId,
    substitutePlayerId
  );

  /**
   * Tournament matches can't be auto-approved.
   * This if statement, guarantees that only league matches has
   * the substitution request auto-approved.
   */
  if (!(await isTournamentMatch(apolloClient, matchId))) {
    return await approveMatchSubstitution(apolloClient, {
      matchId,
      substitutions: substitutionRequest?.match?.substitutions,
      playerId: playerId,
      substitutePlayerId: substitutePlayerId,
    });
  }

  return substitutionRequest;
}
