export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const GRAPHQL_API_ENDPOINT = `${API_BASE_URL}/v1/graphql`;
export const DIVISION_SLOTS: Array<string> = ['A', 'B', 'C', 'D', 'E', 'F'];
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || '';
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
export const AUTH0_REALM = process.env.REACT_APP_AUTH0_REALM || '';
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE || '';
export const PUSHER_APP_ID = process.env.REACT_APP_PUSHER_APP_ID;
export const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY || '';
export const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;
export const PUSHER_AUTH_ENDPOINT = process.env.REACT_APP_PUSHER_AUTH_ENDPOINT;

type NODE_ENV_TYPE = 'dev' | 'prod' | undefined;
export const NODE_ENV: NODE_ENV_TYPE = process.env
  .REACT_APP_NODE_ENV as NODE_ENV_TYPE;
