const styled = require('styled-components');
const { default: tw } = require('twin.macro');

const TypographyGlobalStyle = styled.createGlobalStyle`
  h1 {
    ${tw`font-medium text-3xl text-gray-500 mb-1`}
  }

  h2 {
    ${tw`font-medium text-2xl text-gray-500 mb-6`}
  }

  h3 {
    ${tw`font-normal text-lg text-gray-500 mb-6`}
  }

  h4 {
    ${tw`font-normal text-base text-gray-500 mb-1`}
  }

  p {
    ${tw`font-normal text-sm text-gray-500 mb-1`}
  }

  a {
    ${tw`text-primary hover:text-primary-lighter hover:underline`}
  }
`;

export default TypographyGlobalStyle;
