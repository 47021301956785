import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import AuthGuard from '~modules/auth/auth-guard';
import { UserRole } from './types/user-role-enum';

const Users = lazy(() => import('./pages/Users'));
const AddUser = lazy(() => import('./pages/AddUser'));
const EditUser = lazy(() => import('./pages/EditUser'));

export const usersRoutes = {
  list: {
    title: () => 'Administrators',
    path: () => '/admin-users',
    roles: [UserRole.ADMIN],
  },
  add: {
    title: () => 'Add Admin User',
    path: () => '/admin-users/add',
    roles: [UserRole.ADMIN],
  },
  edit: {
    title: () => 'Edit Admin User',
    path: (id?: string) => `/admin-users/${id ? id : ':id'}`,
    roles: [UserRole.ADMIN],
  },
};

export function UsersRoutes(): RouteObject[] {
  return [
    {
      path: usersRoutes.list.path(),
      element: (
        <AuthGuard roles={usersRoutes.list.roles}>
          <Users title={usersRoutes.list.title()} />
        </AuthGuard>
      ),
    },
    {
      path: usersRoutes.add.path(),
      element: (
        <AuthGuard roles={usersRoutes.add.roles}>
          <AddUser title={usersRoutes.add.title()} />
        </AuthGuard>
      ),
    },
    {
      path: usersRoutes.edit.path(),
      element: (
        <AuthGuard roles={usersRoutes.edit.roles}>
          <EditUser title={usersRoutes.edit.title()} />
        </AuthGuard>
      ),
    },
  ]
};
