export enum UserRole {
  ADMIN = 'admin',
  OPERATOR = 'operator',
  PLAYER = 'player',
}

export function getUserRoleLabel(role: UserRole) {
  switch (role) {
    case UserRole.ADMIN:
      return 'Admin';

    case UserRole.OPERATOR:
      return 'Operator';

    case UserRole.PLAYER:
      return 'Player';

    default:
      return '';
  }
}
