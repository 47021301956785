import React from 'react';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

const StyledTable = styled.table<{ $styledHeader?: boolean }>`
  ${tw`font-normal text-left text-gray-500 mb-6`}

  tr {
    &:hover td {
      ${tw`bg-gray-50`}
    }
  }

  th,
  td {
    ${tw`p-3 border-b`}
  }
  th {
    ${tw`font-medium border-gray-300`}
  }
  td {
    ${tw`border-gray-200`}
  }

  ${(props) =>
    props.$styledHeader &&
    css`
      th {
        ${tw`bg-gray-100 border-gray-200`}
      }
    `}
`;

export interface TableProps extends React.PropsWithChildren {
  className?: string;
  styledHeader?: boolean;
}

const Table: React.FC<TableProps> = ({ children, className, styledHeader }) => {
  function getCssClasses() {
    let classes = ['w-full'];

    if (className) classes.push(className);

    return classes.join(' ');
  }

  return (
    <StyledTable $styledHeader={styledHeader} className={getCssClasses()}>
      {children}
    </StyledTable>
  );
};

export default Table;
